export const embauchesFilters = [
  {
    text: "Mois de signature",
    value: "mois_signature", 
    selected: []
  },
  {
    text: "Année de signature",
    value: "annee_signature",
    selected: []
  },
  {
    text: "Mois d'entrée",
    value: "mois_entree",
    selected: []
  },
  {
    text: "Année d'entrée",
    value: "annee_entree",
    selected: []
  },
  {
    text: "Date de signature",
    value: "date_signature",
    selected: []
  },
  {
    text: "Date d'entrée",
    value: "date_entree",
    selected: []
  },
  {
    text: "Sexe",
    value: "sexe",
    selected: []
  },
  {
    text: "Ecole",
    value: "ecole",
    selected: []
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    selected: []
  },
  {
    text: "Rang école",
    value: "rang_ecole",
    selected: []
  },
  {
    text: "Pôle",
    value: "pole",
    selected: []
  },
  {
    text: "Salaire",
    value: "salaire",
    selected: []
  },
  {
    text: "Expérience",
    value: "experience",
    selected: []
  },
  {
    text: "Origine",
    value: "origine",
    selected: []
  },
  {
    text: "Précision origine",
    value: "precision_origine",
    selected: []
  },
  {
    text: "Profile",
    value: "profil",
    selected: []
  },
  {
    text: "HP",
    value: "high_potential",
    selected: []
  },
  {
    text: "TAC",
    value: "trigramme",
    selected: []
  },
  {
    text: "Année de diplomation",
    value: "annee_diplomation",
    selected: []
  },
  {
    text: "Fonction",
    value: "nom_fonction",
    selected: []
  },
  // {
  //   text: "Matricule",
  //   value: "matricule",
  //   selected: []
  // },
];
    