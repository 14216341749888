export const embaucheHeaders = [
  {
    text: "TAC",
    value: "trigramme",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date de signature",
    value: "date_signature",
    sortable: false,
    align: 'center',
  },
  {
    text: "Mois de signature",
    value: "mois_signature",
    sortable: false,
    align: 'center',
  },
  {
    text: "Annee de signature",
    value: "annee_signature",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date d'entrée",
    value: "date_entree",
    sortable: false,
    align: 'center',
  },
  {
    text: "Mois d'entrée",
    value: "mois_entree",
    sortable: false,
    align: 'center',
  },
  {
    text: "Annee d'entrée",
    value: "annee_entree",
    sortable: false,
    align: 'center',
  },
  {
    text: "Matricule",
    value: "matricule",
    sortable: false,
    align: 'center',
  },
  {
    text: "Emploi",
    value: "profil",
    sortable: false,
    align: 'center',
  },
  {
    text: "Fonction",
    value: "nom_fonction",
    sortable: false,
    align: 'center',
  },
  {
    text: "Nom",
    value: "nom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Prenom",
    value: "prenom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Sexe",
    value: "sexe",
    sortable: false,
    align: 'center',
  },
  {
    text: "Ecole",
    value: "ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Année de diplomation",
    value: "annee_diplomation",
    sortable: false,
    align: 'center',
  },
  {
    text: "Rang école",
    value: "rang_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Pôle",
    value: "pole",
    sortable: false,
    align: 'center',
  },
  {
    text: "HP",
    value: "high_potential",
    sortable: false,
    align: 'center',
  },
  {
    text: "Salaire",
    value: "salaire",
    sortable: false,
    align: 'center',
  },
  {
    text: "Exp.",
    value: "experience",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date d'ancienneté pro",
    value: "anciennete",
    sortable: false,
    align: 'center',
  },
  {
    text: "Origine",
    value: "origine",
    sortable: false,
    align: 'center',
  },

  {
    text: "Actions",
    value: "actions",
    sortable: false,
    align: 'center',
  },

];
